import {
  getAllUsers,
  getAllUsersForAdmin,
} from "api/users";
import { Loader, DataTable, TableManager, BorderGradient } from "processhub-components-lib";
import { useEffect, useMemo, useState } from "react";
import Debounce from "utilities/Debounce";
import { getRoles } from "api/users";
import { UpdateUserRole } from "api/users";
import useApp from "hooks/useApp";

export enum Roles {
  Admin = "admin",
  User = "user",
}
export default function UsersContainer() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState<any>(null);
  const [rawData, setRawData] = useState<any>(null);
  const { userRole } = useApp();

  const getAdminData = async () => {
    setLoading(true);
    const response: any = await getAllUsersForAdmin();
    if (response.status === 200) {
      setRawData(response.data);
    } else {
      setError(true);
    }

    setLoading(false);
  };

  const getData = async () => {
    setLoading(true);
    const response: any = await getAllUsers();
    if (response.status === 200) {
      setRawData(response.data);
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const refreshData = async () => {
    if (userRole === "admin") {
      const response: any = await getAllUsersForAdmin();
      if (response.status === 200) {
        setRawData(response.data);
      } else {
        setError(true);
      }
    } else {
      const response: any = await getAllUsers();
      if (response.status === 200) {
        setRawData(response.data);
      } else {
        setError(true);
      }
    }
  };

  useEffect(() => {
    if (userRole === "admin") {
      getAdminData();
    } else {
      getData();
    }
  }, [userRole]);

  useEffect(() => {
    if (rawData && rawData.length) {
      setData(rawData);
    }
  }, [rawData]);

  const [keyword, setKeyword] = useState("");
  const [debouncedKeyword, setDebouncedKeyword] = useState("");
  Debounce(() => setDebouncedKeyword(keyword), [keyword], 500);

  const rows = useMemo(() => {
    let items = data;
    const keyword = debouncedKeyword.toString().toLowerCase();
    if (keyword !== "") {
      items = data?.filter(
        (item: any) =>
          item.name.toLowerCase() === keyword ||
          item.name.toLowerCase().includes(keyword)
      );
    }
    return items;
  }, [debouncedKeyword, data, rawData]);

  const onClickHandler = () => { };
  const onActionClickHandler = () => { };

  return (
    <>
      <div className="outline overflow-y-auto h-full p-4">
        {loading && (
          <div className="w-full h-[80vh] grid place-content-center">
            <Loader />
          </div>
        )}
        {error && (
          <div className="w-full h-[80vh] grid place-content-center">
            <p>Error loading data</p>
          </div>
        )}
        {!loading && !error && data && (
          <div className="overflow-hidden overflow-y-scroll">
            <div className="3xl:mb-4">
              <TableManager type="noBtnAction" setKeyword={setKeyword} btnAction={() => null} />
            </div>
            <div className="pt-3 h-[84vh]">
              <BorderGradient className="overflow-hidden h-full">
                <DataTable type="usersAdmin" rows={rows} hasRoles={true} hasPins={false} hasActions={false} onClickHandler={onClickHandler} onActionClickHandler={onActionClickHandler} refresh={refreshData} userRole={userRole} getRoles={getRoles} updateUserRole={UpdateUserRole} />
                <DataTable type="usersNotAdmin" rows={rows} hasRoles={false} hasPins={false} hasActions={false} onClickHandler={onClickHandler} onActionClickHandler={onActionClickHandler} refresh={refreshData} userRole={userRole} getRoles={getRoles} updateUserRole={UpdateUserRole} />
              </BorderGradient>
            </div>
          </div>
        )}
      </div >
    </>
  );
}
