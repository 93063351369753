import useApp from "./useApp";
import useScreen from "./useScreen";
import useSignalR from "./useSignalR";

export const allHooks = {
  useApp,
  useData: null,
  useScreen,
  useSignalR,
  useCoordinates: null,
  useUnity: null,
  usePins: null,
};