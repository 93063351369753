import logoImg from "assets/logo.svg";
import { Loader } from 'processhub-components-lib';
import classNames from "utilities/ClassNames";
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

interface SplashScreenInterface {
  open: boolean;
  logo?: boolean;
  flicker1?: string;
  flicker2?: string;
  status?: string;
  isScaled?: any;
  progressStatus: number;
}
function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          sx={{
            height: 20,
            borderRadius: 10,
            backgroundColor: '#333333',
            '& .MuiLinearProgress-bar': {
              backgroundColor: '#47c2fd',
            },
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="white">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export const SplashScreen: React.FunctionComponent<SplashScreenInterface> = ({
  open = false,
  logo = false,
  flicker1 = "LOAD",
  flicker2 = "ING",
  status,
  isScaled,
  progressStatus,
}) => {
  if (open) {
    return (
      <>
        <div className="absolute grid place-content-center h-full w-full bg-black">
          <div className="flex flex-col items-center gap-5">
            <img
              src={logoImg}
              alt="Processhub"
              className={classNames(isScaled ? "logo-large" : "logo-medium")}
            />
            <Loader />
            <div className={classNames(isScaled ? "text-2xl" : "text-base")}>
              {status}
            </div>
            <Box sx={{ width: '100%' }}>
              <LinearProgressWithLabel value={progressStatus} />
            </Box>
          </div>
        </div>
        {/* <div className="loader-container">
          <div className="loader-content">
            {logo && (
              <img src={logoImg} alt="Processhub" className="logo-medium" />
            )}
            <div className="loader">
              <span
                data-flicker-0={flicker1}
                data-flicker-1={flicker2}
                className="loader-text"
              >
                {flicker1 + flicker2}
              </span>
            </div>
            
          </div>
          <div className="loader-status">{status}</div>
        </div> */}
      </>
    );
  } else {
    return <></>;
  }
};
