import { AiOutlineFieldTime } from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import { RxGlobe } from "react-icons/rx";
import { TbBrandUnity } from "react-icons/tb";

export const navLinksConfig = (isScaled: any) => {
  return (
    [
      {
        to: "/",
        icon: <TbBrandUnity size={isScaled ? 18 : 20} />,
        text: "3D Data Center",
      },
      {
        to: "/users",
        icon: <FiUsers size={isScaled ? 18 : 20} />,
        text: "Users",
      },
      {
        to: "/logs",
        icon: <AiOutlineFieldTime size={isScaled ? 18 : 20} />,
        text: "Logs",
      },
      {
        to: `${process.env.REACT_APP_COMMON_APP}`,
        icon: <RxGlobe size={isScaled ? 18 : 20} />,
        text: "Back to Saudi Map",
      },
    ]

  );
};