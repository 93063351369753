import Layout from "components/layout";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthGuard } from "utilities/AuthGuard";
import "./App.css";
import UnityWP from "pages/waterplant";
import Users from "pages/users/UsersContainer";
import Logs from "pages/Logs";
import watermarkBg from "assets/watermarkBg.png";

function App() {
  return (
    <>
      <Routes>
        <Route element={<AuthGuard />}>
          <Route element={<Layout />}>
            <Route index path="/" element={<UnityWP />}></Route>
            <Route path="/users" element={<Users />}></Route>
            <Route path="/logs" element={<Logs />} />
          </Route>
          {/* </Route> */}
          <Route path="*" element={<p>Not Found</p>} />
        </Route>
      </Routes>
      <div className="w-full h-full absolute left-0 top-0 opacity-[0.06]" style={{
        backgroundImage: `url(${watermarkBg})`,
        backgroundPosition: '30px 70px',
        backgroundRepeat: 'repeat',
        backgroundSize: '400px auto',
        pointerEvents: 'none',
      }} />
    </>
  );
}

export default App;
